

import React from 'react'
import universal, { setHasBabelPlugin } from '/Users/tainguyen/neo-one/common/temp/node_modules/react-universal-component/dist/index.js'

setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
  ignoreBabelRename: true
}

const t_0 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/404'), universalOptions)
      t_0.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/404'
      
const t_1 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/index'), universalOptions)
      t_1.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/index'
      
const t_2 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/course'), universalOptions)
      t_2.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/course'
      
const t_3 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/docs'), universalOptions)
      t_3.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/docs'
      
const t_4 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/docsRedirect'), universalOptions)
      t_4.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/docsRedirect'
      
const t_5 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/tutorial'), universalOptions)
      t_5.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/tutorial'
      
const t_6 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blog'), universalOptions)
      t_6.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blog'
      
const t_7 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blogAll'), universalOptions)
      t_7.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blogAll'
      
const t_8 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blogRedirect'), universalOptions)
      t_8.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blogRedirect'
      
const t_9 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/reference'), universalOptions)
      t_9.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/reference'
      
const t_10 = universal(import('/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/referenceRedirect'), universalOptions)
      t_10.template = '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/referenceRedirect'
      

// Template Map
export default {
  '/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/404': t_0,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/index': t_1,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/course': t_2,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/docs': t_3,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/docsRedirect': t_4,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/tutorial': t_5,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blog': t_6,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blogAll': t_7,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/blogRedirect': t_8,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/reference': t_9,
'/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/referenceRedirect': t_10
}
// Not Found Template
export const notFoundTemplate = "/Users/tainguyen/neo-one/packages/neo-one-website/src/pages/404"

